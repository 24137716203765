<template>
  <div>
    <Widgets v-if="page?.widgets" :widgets="page?.widgets" />
  </div>
</template>

<script lang="ts" setup>
import { useBreadcrumbs } from '@/state/breadcrumbs'
import { useAnimationDisabled } from '@/state/pixelAnimationData'

const { data: page } = await useAsyncData('page', () =>
  useApi().publicPagesSimplePagesRetrieve('home')
)

const pixelAnimationDisabled = useAnimationDisabled()
pixelAnimationDisabled.value = Boolean(page.value?.pixels_disabled)

const { setBreadcrumbs } = useBreadcrumbs()
setBreadcrumbs([])

const head = useLocaleHead({
  identifierAttribute: 'id',
  addSeoAttributes: true
})

const meta = usePrepareSEOMeta(page)

useHead({
  title: page.value?.title,
  link: head.value.link,
  meta: [...(head.value.meta ?? []), ...meta]
})
</script>
